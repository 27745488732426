<template>
  <el-table height="calc(100% - 100px)" border :data="list" v-loading="loading" empty-text="暂无数据"
            style="width: 100%"
  >
    <template v-for="(item,index) in columns">
      <el-table-column v-if="item.type === 'text'" :min-width="item.width" :prop="item.prop" :label="item.label"/>
      <el-table-column v-if="item.type === 'tag'" :min-width="item.width" :prop="item.prop" :label="item.label">
        <template #default="scope">
          <div v-for="item2 in item.arr">
            <el-tag v-if="scope.row[item.prop] === item2.key" :type="item2.type">{{ item2.label }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="item.type === 'arr'" :min-width="item.width" :prop="item.prop" :label="item.label">
        <template #default="scope">
          <span>{{ scope.row[item.prop].map(x => x.name).join(',') }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="item.type === 'json'" :min-width="item.width" :prop="item.prop" :label="item.label">
        <template #default="scope">
          <div>{{ nameHandle(scope.row[item.prop],scope.row[item.deputy]) }}</div>
        </template>
      </el-table-column>
      <el-table-column v-if="item.type === 'select'" :min-width="item.width" :prop="item.prop" :label="item.label">
        <template #default="scope">
          <div>{{ nameHandle2(scope.row[item.prop],item.options) }}</div>
        </template>
      </el-table-column>
      <el-table-column v-if="item.type === 'switch'" :min-width="item.width" :label="item.label">
        <template #default="scope">
          <el-switch v-model="scope.row[item.prop]" @change="item.change($event,scope.row)" />
        </template>
      </el-table-column>
      <el-table-column v-if="item.type === 'but'" :min-width="item.width" :label="item.label">
        <template #default="scope">
          <div class="list-but">
            <el-button v-for="item2 in item.arr" :type="item2.type" :icon="item2.icon" @click="item2.onClick(scope.row)">{{ item2.label }}</el-button>
          </div>
        </template>
      </el-table-column>
    </template>
  </el-table>
  <el-row v-if="pageShow" justify="center" style="padding-top: 16px;" ref="pager">
    <el-pagination background layout="prev, pager, next"
                   :current-page="page"
                   :page-size="pageSize"
                   :total="total"
                   @current-change="handlePageChange"
    ></el-pagination>
  </el-row>
</template>

<script>
export default {
  name: 'listTable',
  data() {
    return {}
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    page: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 15
    },
    total: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array,
      default: () => []
    },
    pageShow: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    nameHandle(v,n) {
      return n.name
    },
    handlePageChange(page) {
      this.$emit('page-change', page)
    },
    nameHandle2(v,n) {
      if(v) {
        const name = n.filter(x => x.value === v)[0]
        return name? name.label : ''
      }
    }
  }
}
</script>

<style scoped>
.list-but {
    display: flex;
}
</style>

<template>
  <el-form
    ref="sellerForm"
    label-position="left"
  >
    <template v-for="(item,index) in columns">
      <el-form-item v-show="!item.show" :label="item.label" :prop="item.prop">
        <el-input v-if="item.type === 'input'" :disabled="item.disabled" v-model="item.value" :placeholder="item.disabled? '':`请输入${item.label}`" autocomplete="off" @input="formIndex"></el-input>
        <el-input v-if="item.type === 'textarea'" rows="3" type="textarea" v-model="item.value" :placeholder="`请输入${item.label}`" autocomplete="off"></el-input>
        <el-select v-if="item.type === 'province'" @change="change($event,item)" v-model="item.value" placeholder="请选择省份" clearable>
          <el-option
            v-for="option in item.options"
            :key="option.id"
            :label="option.name"
            :value="option.id">
          </el-option>
        </el-select>
        <el-select v-if="item.type === 'city'" v-model="item.value" placeholder="请选择省份" clearable>
          <el-option
            v-for="option in cityData"
            :key="option.id"
            :label="option.name"
            :value="option.id">
          </el-option>
        </el-select>
        <div class="form-code" v-if="item.type === 'code'">
          <el-input v-model="item.value" :placeholder="`请输入${item.label}`" autocomplete="off"></el-input>
          <el-button class="code-but" type="primary" @click="sendCode" size="small">获取验证码</el-button>
        </div>
        <p v-if="item.type === 'text'&&!item.show">{{ item.value }}</p>
        <el-date-picker
          v-if="item.type === 'date'"
          v-model="item.value"
          type="date"
          placeholder="请选择时间"
        />
        <el-select v-if="item.type === 'select'" @change="selectChange($event,item,index)" v-model="item.value" :placeholder="`请选择${item.label}`" clearable>
          <el-option
            v-for="option in item.options"
            :key="option.value"
            :label="option.label"
            :value="option.value">
          </el-option>
        </el-select>
        <el-select v-if="item.type === 'selectMultiple'" multiple v-model="item.value" :placeholder="`请选择${item.label}`" clearable>
          <el-option
            v-for="option in item.options"
            :key="option.value"
            :label="option.label"
            :value="option.value">
          </el-option>
        </el-select>
        <Course v-if="item.type === 'course'" @choice="courseChoice($event,item)"></Course>
        <Package v-if="item.type === 'package'" :data="courseName" @choice="packageChoice($event,item)"></Package>
        <Goods v-if="item.type === 'goods'" @choice="goodsChoice($event,item)"></Goods>
        <Upload v-if="item.type === 'file'" :list="fileUrl(item.value)" :limit="item.limit" @upload="upload($event,item)" />
        <Upload v-if="item.type === 'img'" type="img" :list="item.value" :limit="item.limit" @upload="upload($event,item)" />
        <div v-if="item.type === 'arr'" class="form-but">
          <el-button type="primary" @click="addList">添加</el-button>
        </div>
        <el-time-select
          v-if="item.type === 'time'"
          v-model="item.value"
          style="width: 100%"
          start="00:00"
          step="00:05"
          end="23:59"
          :placeholder="`请选择${item.label}`"
        />
        <div v-if="item.type === 'teacher'" class="right">
          <em class="see-but" @click="teacherTap">添加教师</em>
          <p>{{ teacherVal }}</p>
        </div>
        <div v-if="item.type === 'student'" class="right">
          <em class="see-but" @click="studentTap">添加学生</em>
          <p>{{ studentVal }}</p>
        </div>
      </el-form-item>
      <div v-if="item.type === 'arr'" class="form-list">
        <div class="form-list-item">
           <table border="0">
              <tr>
                <th>账号</th>
                <th>联系人</th>
                <th>职位</th>
                <th style="width: 160px;">操作</th>
              </tr>
              <tr v-for="(item2,index2) in item.value" :key="index2">
                <td>{{ item2.username }}</td>
                <td>{{ item2.name }}</td>
                <td>{{ item2.position }}</td>
                <td>
                  <el-button @click="changePass(index2)">修改密码</el-button>
                  <el-button type="danger" @click="del(index2)">删除</el-button>
                </td>
              </tr>
           </table>
        </div>
      </div>
    </template>
    <el-form-item>
      <div style="margin-top: 20px;">
        <el-button style="min-width: 72px;" type="primary" @click="Submit">提交</el-button>
      </div>
    </el-form-item>
  </el-form>
<!--  添加账号-->
  <el-dialog v-model="formDrawerVisible" :width="400" title="添加账号">
    <el-form label-position="left">
      <el-form-item label="账号">
        <el-input v-model="username" placeholder="请输入账号" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="联系人">
        <el-input v-model="name" placeholder="请输入联系人" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="职位">
        <el-input v-model="position" placeholder="请输入职位" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input v-model="password" placeholder="请输入密码" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div class="user-but">
      <el-button type="primary" @click="addUser">确认</el-button>
    </div>
  </el-dialog>
<!--  修改密码-->
  <el-dialog v-model="formDrawerVisible2" :width="400" title="添加账号">
    <el-form label-position="left">
      <el-form-item label="账号">
        <el-input v-model="password" placeholder="请输入新密码" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div class="user-but">
      <el-button type="primary" @click="userPass">确认</el-button>
    </div>
  </el-dialog>
<!--  学生,教师列表-->
  <el-dialog v-model="formDrawerVisible3" :width="800" :title="listType === 2?'教师列表':'学生列表'">
     <List v-if="formDrawerVisible3" :data="listType === 2?teacherId:studentId" :type="listType" @change="changeList"></List>
  </el-dialog>
</template>

<script>
import Upload from '@/components/upload.vue'
import List from '@/components/list.vue'
import { sendSms } from '@/apis/common'
import Goods from '@/components/goods.vue'
import Course from '@/components/course.vue'
import Package from '@/components/package.vue'
export default {
  name: 'form',
  components: { Package, Course, Goods, Upload,List },
  data() {
    return {
      cityData: [],
      formDrawerVisible: false,
      formDrawerVisible2: false,
      formDrawerVisible3: false,
      username: '',
      password: '',
      name: '',
      position: '',
      passwordIndex: 0,
      fileList: [],
      listType: 1,
      teacherVal: '',
      teacherId: '',
      studentVal: '',
      studentId: '',
      linkageStart: '',
      linkageArr: [],
      courseName: ''
    }
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'add'
    }
  },
  mounted() {
    this.getRegion()
    this.columns.forEach((x,i) => {
      if(x.type === 'teacher') {
        this.teacherVal = x.options.map(x => x.name).join(',')
        this.teacherId = x.options.map(x => x.id).join(',')
      }
      if(x.type === 'student') {
        this.studentVal = x.options.map(x => x.name).join(',')
        this.studentId = x.options.map(x => x.id).join(',')
      }
      if(x.linkage&&x.main) {
        this.linkageArr.push({
          index: i,
          value: x.value,
          linkage: x.linkage
        })
      }
    })
    this.linkageArr.forEach(x => {
      this.columns.map((y,j) => {
        if(y.linkage === x.linkage&&!y.main) {
          const arr = y.linkageValue.split(',').map(k => {
            k = Number(k)
            return k
          })
          y.show = x.value === ''? false :!arr.includes(x.value)
        }
        return y
      })
    })
  },
  methods:{
    formIndex() {
      const single = this.columns.filter(x => x.single)[0]
      const num = this.columns.filter(x => x.num)[0]
      this.columns.map(x => {
        if(x.and) x.value = (Number(single.value) * Number(num.value)).toFixed(2)
        return x
      })
    },
    selectChange(e,v,j) {
      if(v.main) {
        this.columns.map((x,i) => {
          if(x.linkage === v.linkage&&!x.main) {
            const arr = x.linkageValue.split(',').map(y => {
              y = Number(y)
              return y
            })
            x.show = !arr.includes(e)
          }
          return x
        })
      }
    },
    courseChoice(e,v) {
      this.courseName = e.name
      v.value = e.id
    },
    packageChoice(e,v) {
      v.value = e.id
    },
    goodsChoice(e,v) {
      v.value = e.id
    },
    sendCode() {
      const phone = this.columns.filter(x => x.prop === 'phone')[0].value
      if(phone === '') {
        this.$message.error('手机号不能为空')
        return
      }
      sendSms({
        mobile: phone,
        inc_type: 1
      }).then(res => {
        this.columns.filter(x => x.prop === 'sms_key')[0].value = res.data.data.key
        this.$message.success('发送成功')
      })
    },
    changeList(e,v) {
      if(v === 2) {
        this.teacherVal = e.map(x => x.name).join(',')
        this.teacherId = e.map(x => x.id).join(',')
      } else {
        this.studentVal = e.map(x => x.name).join(',')
        this.studentId = e.map(x => x.id).join(',')
      }
      this.formDrawerVisible3 = false
    },
    teacherTap() {
      this.listType = 2
      this.formDrawerVisible3 = true
    },
    studentTap() {
      this.listType = 1
      this.formDrawerVisible3 = true
    },
    fileUrl(e) {
      if(typeof e === 'string') {
        const name = e.split('/')
        return e === ''? []: [{url: e,name: name[name.length - 1]}]
      } else {
        return e.map(x => {
          const name = x.split('/')
          return {url: x,name: name[name.length - 1]}
        })
      }
    },
    upload(e,v) {
      if(v.type === 'img') {
        v.value = e.map(x => x.url).join(',')
      } else {
        v.value = v.limit === 1? e[0].url: e
      }
    },
    userPass() {
      this.columns.forEach(item => {
        if (item.type === 'arr') {
          item.value[this.passwordIndex].password = this.password
        }
      })
      this.formDrawerVisible2 = false
    },
    changePass(i) {
      this.passwordIndex = i
      this.password = ''
      this.formDrawerVisible2 = true
    },
    del(i) {
      this.columns.forEach(item => {
        if (item.type === 'arr') {
          item.value.splice(i, 1)
        }
      })
    },
    addUser() {
      if(this.username === '' || this.password === '') {
        this.$message.error('账号密码不能为空')
        return
      }
      this.columns.forEach(item => {
        if (item.type === 'arr') {
          if(item.value === '') item.value = []
          item.value.push({
            id: '',
            username: this.username,
            password: this.password,
            name: this.name,
            position: this.position
          })
        }
      })
      this.formDrawerVisible = false
    },
    addList() {
      this.username = ''
      this.password = ''
      this.name = ''
      this.position = ''
      this.formDrawerVisible = true
    },
    Submit() {
      let params = {}
      this.columns.forEach(item => {
        params[item.prop] = item.value
        if(item.type === 'arr') {
          params[item.prop] = item.value.map(x => {
            x.password = x.password || ''
            return x
          })
          params[item.prop] = JSON.stringify(params[item.prop])
        }
        if(item.type === 'date') {
          if(item.value === '') return ''
          params[item.prop] = this.$moment(item.value).format('YYYY-MM-DD')
        }
        if(item.type === 'teacher') {
          params[item.prop] = this.teacherId
        }
        if(item.type === 'student') {
          params[item.prop] = this.studentId
        }
        if(item.type === 'selectMultiple') {
          params[item.prop] = item.value.join(',')
        }
      })
      console.log(params)
      this.$emit('submit', params, this.type)
    },
    change(e,v) {
      if(e === '') {
        this.cityData = []
        return
      }
      const city = v.options.filter(x => x.id === e)[0]
      this.cityData = city.city
    },
    getRegion() {
      this.columns.forEach(item => {
        if (item.type === 'province') {
          item.options = this.$store.state.address
          if(this.type === 'edit') {
            const city = item.options.filter(x => x.id === item.value)[0]
            this.cityData = city.city
          }
        }
      })
    },
  }
}
</script>

<style scoped>
.right{
    text-align: right;
}
.see-but{
    display: inline-block;
    padding: 9px 16px;
    background-color: #409EFF;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
    font-size: 12px;
    height: 32px;
    line-height: 13px;
    box-sizing: border-box;
}
.icons svg{
    width: 60px;
    height: 60px;
    color: #ccc;
}
.user-but{
    text-align: right;
    margin-top: 20px;
}
.form-list-item table{
    width: 100%;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
}
.form-list-item th,.form-list-item td{
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    padding: 5px;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}
.form-but{
    text-align: right;
}
:deep(.el-form-item__label){
    width: 100px;
}
:deep(.el-select){
    width: 100%;
}
:deep(.el-date-editor){
    width: 100%;
}
:deep(.el-upload) {
    width: 100%;
    text-align: left;
}
.code-but{
    margin-top: 20px;
}
</style>

<template>
  <div class="upload" v-if="type === 'file'">
    <label>
      <input ref="fileRef" type="file" style="display: none;" @change="fileInput"/>
      <span class="upload-but">{{ loading? '上传中...' :'上传文件' }}</span>
    </label>
    <div class="upload-list">
      <div class="upload-list-item" v-for="(item,index) in uploadList">
        <i class="icon-text">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
            <path fill="currentColor"
                  d="M832 384H576V128H192v768h640zm-26.496-64L640 154.496V320zM160 64h480l256 256v608a32 32 0 0 1-32 32H160a32 32 0 0 1-32-32V96a32 32 0 0 1 32-32m160 448h384v64H320zm0-192h160v64H320zm0 384h384v64H320z"
            ></path>
          </svg>
        </i>
        <span>{{ item.name }}</span>
        <i class="icon-col" @click="uploadDel(index)">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
            <path fill="currentColor"
                  d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z"
            ></path>
          </svg>
        </i>
      </div>
    </div>
  </div>
  <div v-else class="upload-img-cont">
    <div class="upload-img" v-for="(item,index) in uploadList">
      <img :src="item.url" alt="" />
      <i class="icon-del" @click="uploadDel(index)">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
          <path fill="currentColor"
                d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z"
          ></path>
        </svg>
      </i>
    </div>
    <div class="upload-img" v-if="uploadList.length < limit">
      <label>
        <input ref="fileRef" type="file" style="display: none;" @change="fileInput"/>
        <i class="icon-col">
          <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-36fef47d=""><path fill="currentColor" d="M480 480V128a32 32 0 0 1 64 0v352h352a32 32 0 1 1 0 64H544v352a32 32 0 1 1-64 0V544H128a32 32 0 0 1 0-64h352z"></path></svg>
        </i>
      </label>
    </div>
  </div>
</template>

<script>
import { uploadToken } from '@/apis/common'

export default {
  name: 'upload',
  data() {
    return {
      uploadList: [],
      loading: false
    }
  },
  props: {
    limit: {
      type: Number,
      default: 1
    },
    list: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'file'
    }
  },
  watch: {
    list: {
      handler(val) {
        if(this.type === 'file') {
          this.uploadList = val
        } else {
          //判断val有没有逗号
          if(val.indexOf(',') !== -1){
            this.uploadList = val.split(',').map(x => {
              return {name: '', url: x}
            })
          }else{
            this.uploadList = val.length === 0 ? [] : [{name: '', url: val}]
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    uploadDel(i) {
      this.uploadList.splice(i, 1)
      this.$emit('upload',this.uploadList)
    },
    fileInput(e) {
      if(this.loading) return
      if(this.uploadList.length >= this.limit) {
        this.$message.error('最多上传' + this.limit + '个文件')
        return
      }
      const file = e.target.files[0]
      this.getUploadToken(file)
      this.$refs.fileRef.value = ''
    },
    getUploadToken(file) {
      let that = this
      that.loading = true
      uploadToken().then(res => {
        const client = new OSS({
          region: res.data.data.region,
          accessKeyId: res.data.data.AccessKeyId,
          accessKeySecret: res.data.data.AccessKeySecret,
          stsToken: res.data.data.SecurityToken,
          bucket: res.data.data.bucket
        })
        const timestamp = new Date().getTime() + '/' + file.name
        client.put(timestamp, file).then(function(r1) {
          that.loading = false
          that.uploadList.push({name: file.name, url: r1.url})
          that.$emit('upload',that.uploadList)
        }).catch(function(err) {
          console.log(err)
        })
      })
    }
  }
}
</script>

<style scoped>
.icon-del{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;
    width: 14px;
    height: 14px;
}
.icon-del svg{
    width: 14px;
    height: 14px;
    position: relative;
    top: -30px;
}
.icon-text{
    position: absolute;
    top: 3px;
    left: 0;
    z-index: 1;
}
.icon-col{
    position: absolute;
    top: 3px;
    right: 0;
    z-index: 1;
    cursor: pointer;
}
.icon-col svg{
    width: 14px;
    height: 14px;
}
.icon-text svg {
    width: 14px;
    height: 14px;
    margin-right: 5px;
}

.upload-list-item {
    display: flex;
    padding: 5px 0;
    height: 24px;
    position: relative;
}

.upload-list-item span {
    font-size: 12px;
    line-height: 24px;
    padding-left: 20px;
    padding-right: 20px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.upload-but {
    display: inline-block;
    padding: 5px 20px;
    background-color: #409EFF;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    line-height: 22px;
    font-size: 12px;
}
.upload-img{
    width: 80px;
    height: 80px;
    line-height: 80px;
    border: 1px solid #eee;
    cursor: pointer;
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
}
.upload-img label{
    display: inline-block;
    width: 100%;
    height: 100%;
}
.upload-img .icon-col{
    right: 50%;
    margin-right: -7px;
}
.upload-img-cont{
    display: flex;
    flex-wrap: wrap;
}
.upload-img img{
    width: 100%;
    height: 100%;
}
</style>

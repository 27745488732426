<template>
  <el-row justify="space-between">
    <el-col>
      <el-form :inline="true">
        <el-form-item v-for="(item,index) in toolbar">
          <el-input v-if="item.type === 'input'" v-model="item.value" clearable style="max-width: 150px;" :placeholder="item.label"></el-input>
          <el-select v-if="item.type === 'select'" v-model="item.value" :placeholder="`请选择${item.label}`" style="width: 150px">
            <el-option
              v-for="item in item.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-select v-if="item.type === 'selectType'" @change="item.change" v-model="item.value" :placeholder="`请选择${item.label}`" style="width: 150px">
            <el-option
              v-for="item in item.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-date-picker
            style="max-width: 150px;"
            v-if="item.type === 'month'"
            v-model="item.value"
            type="month"
            :placeholder="`请选择${item.label}`"
          />
          <el-date-picker
            style="max-width: 150px;"
            v-if="item.type === 'date'"
            v-model="item.value"
            type="date"
            :placeholder="`请选择${item.label}`"
          />
          <el-button style="min-width: 72px;" v-if="item.type === 'button'" :type="item.def" :icon="item.icon" @click="toolTap(item)">{{ item.label }}</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'listToolbar',
  data() {
    return {}
  },
  props: {
    toolbar: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    toolTap(v) {
      if(v.key === 'add') {
        this.$emit('addTap')
        return
      }
      let params = {}
      this.toolbar.forEach(x => {
        if(x.type !== 'button') {
          params[x.model] = x.value
        }
        if(x.type === 'month') {
          if(x.value === null || x.value === '') {
            params[x.model] = ''
          }else {
            params[x.model] = this.$moment(x.value).format('YYYY-MM')
          }
        }
        if(x.type === 'date') {
          if(x.value === '') return ''
          params[x.model] = this.$moment(x.value).format('YYYY-MM-DD')
        }
      })
      this.$emit('toolTap', params)
    },
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="goods-but">
    <span>{{ name }}</span>
    <el-button type="primary" @click="onProduct">选择课程</el-button>
  </div>
  <el-dialog v-model="formDrawerVisible" :width="800" title="课程列表">
    <el-table height="540px" border :data="list" v-loading="loading" empty-text="暂无数据" style="width: 100%">
      <el-table-column prop="major_id" label="课程ID" />
      <el-table-column prop="name" min-width="200" label="课程名称" />
      <el-table-column prop="minute" label="时长" />
      <el-table-column label="操作" min-width="60">
        <template #default="scope">
          <div class="list-but">
            <el-button type="primary" @click="choice(scope.row)">选择</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import { courseList } from '@/apis/common'

export default {
  data() {
    return {
      info: JSON.parse(sessionStorage.getItem('user')) || {},
      formDrawerVisible: false,
      loading: false,
      list: [],
      name: ''
    }
  },
  mounted() {
    this.getSchoolGoodsList()
  },
  methods: {
    choice(e) {
      this.name = e.name
      this.$emit('choice', e)
      this.formDrawerVisible = false
    },
    onProduct() {
      this.formDrawerVisible = true
    },
    getSchoolGoodsList() {
      this.loading = true
      courseList({
        admin_id: this.info.id,
        school_id: this.info.agency_school_id,
      }).then(res => {
        this.list = res.data.data
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.goods-but > span {
    width: calc(100% - 80px);
    display: inline-block;
}
</style>

<template>
  <div class="goods-but">
    <span>{{ name }}</span>
    <el-button type="primary" @click="onProduct">选择商品</el-button>
  </div>
  <el-dialog v-model="formDrawerVisible" :width="800" title="商品列表">
    <el-table height="540px" border :data="list" v-loading="loading" empty-text="暂无数据" style="width: 100%">
      <el-table-column prop="id" label="ID" />
      <el-table-column prop="goods_name" min-width="200" label="商品名称" />
      <el-table-column prop="buying_price" label="标准进货价" />
      <el-table-column prop="sale_price" label="标准销售价" />
      <el-table-column prop="stock" label="库存" />
      <el-table-column label="操作" min-width="60">
        <template #default="scope">
          <div class="list-but">
            <el-button type="primary" @click="choice(scope.row)">选择</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-row justify="center" style="padding-top: 16px;" ref="pager">
      <el-pagination background layout="prev, pager, next"
                     :current-page="page"
                     :page-size="pageSize"
                     :total="total"
                     @current-change="handlePageChange"
      ></el-pagination>
    </el-row>
  </el-dialog>
</template>

<script>
import { schoolGoodsList } from '@/apis/common'

export default {
  name: 'goods',
  data() {
    return {
      info: JSON.parse(sessionStorage.getItem('user')) || {},
      formDrawerVisible: false,
      loading: false,
      list: [],
      page: 1,
      pageSize: 15,
      total: 0,
      name: ''
    }
  },
  mounted() {
    this.getSchoolGoodsList()
  },
  methods: {
    choice(e) {
      this.name = e.goods_name
      this.$emit('choice', e)
      this.formDrawerVisible = false
    },
    handlePageChange(page) {
      this.page = page
      this.getSchoolGoodsList()
    },
    onProduct() {
      this.formDrawerVisible = true
    },
    getSchoolGoodsList() {
      this.loading = true
      schoolGoodsList({
        admin_id: this.info.id,
        school_id: this.info.agency_school_id,
        page: this.page,
        pagesize: this.pageSize
      }).then(res => {
        this.total = res.data.data.count
        this.list = res.data.data.list
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.goods-but > span {
    width: calc(100% - 80px);
    display: inline-block;
}
</style>

<template>
  <div class="list-header">
    <el-input v-model="searchValue" clearable style="max-width: 200px;" placeholder="输入姓名搜索"></el-input>
    <el-button class="search" type="primary" @click="searchTap">搜索</el-button>
  </div>
  <div class="list-cont">
    <el-table ref="multipleTableRef" height="540px" border :data="filterMsg" v-loading="loading" empty-text="暂无数据" style="width: 100%" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" />
      <el-table-column prop="id" label="ID" />
      <el-table-column prop="name" label="姓名" />
      <el-table-column prop="nickname" label="昵称" />
      <el-table-column prop="phone" label="手机号" />
    </el-table>
  </div>
  <div class="list-but">
    <el-button type="primary" @click="confirm">确认</el-button>
  </div>
</template>

<script>
import { userList } from '@/apis/common'

export default {
  name: 'list',
  data() {
    return {
      info: JSON.parse(sessionStorage.getItem('user')) || {},
      page: 1,
      pageSize: 40,
      loading: false,
      list: [],
      changeData: [],
      searchValue: '',
      filterMsg: []
    }
  },
  props: {
    type: {
      type: Number,
      default: 1
    },
    data: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.getUserList()
  },
  methods: {
    searchTap() {
      this.filterMsg = []
      if(this.searchValue === '') {
        this.filterMsg = this.list
        return
      }
      this.list.map(item => {
        if (item.name.indexOf(this.searchValue) !== -1) {
          this.filterMsg.push(item);
        }
      })
    },
    confirm() {
      if(this.changeData.length === 0) {
        this.$message.error('至少选择一个')
        return
      }
      this.$emit('change', this.changeData, this.type)
    },
    handleSelectionChange(e) {
      this.changeData = e.map(x => {
        return {
          id: x.id,
          name: x.name
        }
      })

    },
    getUserList() {
      this.loading = true
      userList({
        page: this.page,
        pagesize: this.pageSize,
        admin_id: this.info.id,
        school_id: this.info.agency_school_id,
        type: this.type,
        is_all: 1
      }).then(res => {
        this.total = res.data.data.count
        this.list = res.data.data.list
        this.list.forEach(x => {
          const id = this.data.split(',')
          id.forEach(y => {
            if(x.id === parseInt(y)) {
              this.$refs.multipleTableRef.toggleRowSelection(x, true)
            }
          })
        })
        this.filterMsg = this.list
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.search{
    margin-left: 20px;
}
.list-header{
    margin-top: -20px;
    padding-bottom: 20px;
}
.list-cont{
    height: 540px;
    overflow: auto;
}
.list-but{
    text-align: center;
    padding-top: 20px;
}
</style>
